<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../assets/img/daily.png" alt="" width="28">
        <h2>环境自检记录表</h2>
      </div>
    </div>
    <div class="contentBody">
      <el-date-picker
          v-model="dateValue" size="small"
          type="daterange"
          class="timeRange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="search()">
      </el-date-picker>
      <div class="result">
        <p class="time">{{ new Date(sd).toLocaleDateString() }} 至 {{ new Date(ed).toLocaleDateString() }} 自检结果</p>
        <p class="tableTitle">屏障环境实验室日温差、温度、相对湿度、空气洁净度、沉降菌落数检测结果</p>
        <el-table :data="labData" class="tableRecord" max-height="460"
                  :header-cell-style="{background:'none',color:'#333',borderWidth:'0px 0px 1px 0px',textAlign:'center',borderColor:'#4F9AFE'}"
                  cell-class-name="tableCellClassName" style="width: 100%">
          <el-table-column prop="name" label="名称" width="100px"></el-table-column>
          <el-table-column label="日温差/℃">
            <el-table-column label="实测">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.tempDifferJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.tempDifferData
                    }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.tempDifferJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.tempDifferJudge
                    }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <!--
            1 ：日温差tempDifferData tempDifferJudge/温度tempData tempJudge  2：相对湿度 humidityData humidityJudge  3：空气洁净度 airCleanData airCleanJudge 4：沉降菌落数 bacterialData  bacterialJudge5：噪音   noiseData   noiseJudge
            6：工作照度  lightData lightJudge 7：气流速度 airflowData airflowJudge  8：换气次数 airChangeData  airChangeJudge 9：最小静压差 pressData pressJudge -->
          <el-table-column label="温度/℃">
            <el-table-column label="实测" show-overflow-tooltip>
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.tempJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.tempData
                    }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.tempJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.tempJudge
                    }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 3：空气洁净度 airCleanData airCleanJudge 4：沉降菌落数 bacterialData  bacterialJudge5：噪音   noiseData   noiseJudge
6：工作照度  lightData lightJudge 7：气流速度 airflowData airflowJudge  8：换气次数 airChangeData  airChangeJudge 9：最小静压差 pressData pressJudge-->
          <el-table-column label="相对湿度/%">
            <el-table-column label="实测">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.humidityJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.humidityData
                    }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.humidityJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.humidityJudge
                    }}</span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column label="空气洁净度/级">
            <el-table-column label="实测">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.airCleanJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.airCleanData
                    }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.airCleanJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.airCleanJudge
                    }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="沉降菌落数(个)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.bacterialJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.bacterialData
                    }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                  <span
                      :class="scope.row.recordsResultMap.bacterialJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                      scope.row.recordsResultMap.bacterialJudge
                    }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <p class="tableTitle">屏障环境实验室噪音、工作照度、气流速度、换气次数、最小静压差检测结果</p>
        <el-table :data="labData" class="tableRecord" max-height="460"
                  :header-cell-style="{background:'none',color:'#333',borderWidth:'0px 0px 1px 0px',textAlign:'center',borderColor:'#4F9AFE'}"
                  cell-class-name="tableCellClassName" style="width: 100%">
          <el-table-column prop="name" label="名称" width="100px"></el-table-column>
          <el-table-column label="噪音/dB(A)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.noiseJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.noiseData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.noiseJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.noiseJudge
                  }}</span>
              </template>
            </el-table-column>

          </el-table-column>
          <el-table-column label="工作照度/lx">
            <el-table-column label="实测" show-overflow-tooltip>
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.lightJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.lightData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.lightJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.lightJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="气流速度/(m/s)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                   <span
                       :class="scope.row.recordsResultMap.airflowJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                       scope.row.recordsResultMap.airflowData
                     }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.airflowJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.airflowJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="换气次数/(次/h)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.airChangeJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.airChangeData
                      }}</span>
              </template>
            </el-table-column>

            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.airChangeJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.airChangeJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="最小静压差/Pa">
            <el-table-column label="实测">
              <template slot-scope="scope">
                   <span
                       :class="scope.row.recordsResultMap.pressJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                       scope.row.recordsResultMap.pressData
                     }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.pressJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.pressJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <p class="tableTitle">屏障环境辅助用房温度、相对湿度、空气洁净度、噪音、工作照度、换气次数、最小静压差检测结果</p>
        <el-table :data="subData" class="tableRecord" max-height="460"
                  :header-cell-style="{background:'none',color:'#333',borderWidth:'0px 0px 1px 0px',textAlign:'center',borderColor:'#4F9AFE'}"
                  cell-class-name="tableCellClassName" style="width: 100%">
          <el-table-column prop="name" label="名称" width="100px"></el-table-column>
          <el-table-column label="温度/℃">
            <el-table-column label="实测" show-overflow-tooltip width="60">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.tempJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.tempData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定" width="60">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.tempJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.tempJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="相对湿度/%">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.tempDifferJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.humidityData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.tempDifferJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.humidityJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="空气洁净度/级">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.airCleanJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.airCleanData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.airCleanJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.airCleanJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="噪音/dB(A)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                   <span
                       :class="scope.row.recordsResultMap.noiseJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                       scope.row.recordsResultMap.noiseData
                     }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.noiseJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.noiseJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="工作照度/lx">
            <el-table-column label="实测" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.lightJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.lightData
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.lightJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.lightJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="换气次数/(次/h)">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.airChangeJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.airChangeData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.airChangeJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.airChangeJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="最小静压差/Pa">
            <el-table-column label="实测">
              <template slot-scope="scope">
                    <span
                        :class="scope.row.recordsResultMap.pressJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                        scope.row.recordsResultMap.pressData
                      }}</span>
              </template>
            </el-table-column>
            <el-table-column label="判定">
              <template slot-scope="scope">
                <span
                    :class="scope.row.recordsResultMap.pressJudge == '合格' ? 'normalColor' : 'errorColor'">{{
                    scope.row.recordsResultMap.pressJudge
                  }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'

moment.locale('zh-cn')
export default {
  name: "selfTest",
  data() {
    return {
      labData: [],
      subData: [],
      dateValue: '',
      sd: moment(new Date()).subtract(1, 'months').valueOf(),
      ed: +moment(),
    }
  },
  mounted() {
    this.getDataFromServer()
  },
  methods: {
    search() {
      this.sd = moment(this.dateValue[0]).valueOf();
      this.ed = moment(this.dateValue[1]).valueOf();
      this.getDataFromServer();
    },
    getDataFromServer() {
      let startDate;
      let endDate;
      if (this.dateValue == '') {
        endDate = this.ed;
        startDate = this.sd;
      } else if (this.dateValue != null && this.dateValue != undefined && (this.dateValue).length == 2) {
        let arr = this.dateValue;
        startDate = moment(arr[0]).valueOf();
        endDate = moment(arr[1]).valueOf()
      } else {
        this.$message.error("参数有误，请核对后再查");
        return;
      }
      /**
       * 项目编号  默认为
       * 1 ：日温差tempDifferData tempDifferJudge/温度tempData tempJudge  2：相对湿度 humidityData humidityJudge  3：空气洁净度 airCleanData airCleanJudge 4：沉降菌落数 bacterialData  bacterialJudge5：噪音   noiseData   noiseJudge
       * 6：工作照度  lightData lightJudge 7：气流速度 airflowData airflowJudge  8：换气次数 airChangeData  airChangeJudge 9：最小静压差 pressData pressJudge*/
      // 发起请求
      this.$get("/daily/ac-room/records", {
        startDate: startDate, // 搜索条件
        endDate: endDate,
      }).then(resp => { // 这里使用箭头函数
        this.labData = resp.data.labData;
        this.subData = resp.data.subData;
      })

    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/daily";
</style>
